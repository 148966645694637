:root {
  --primary: #00da91;
  --primaryDark: #00c481;
  --dark: #272726;
  --primaryDarkTransparent: rgba(0, 196, 129, 0.3);
}

html,
body {
  background-color: #f6f8fa;
}

.form-select:focus {
  border-color: var(--primary);
  box-shadow: 0 0 0 0.25rem var(--primaryDarkTransparent);
}

.accordion-button:not(.collapsed) {
  color: var(--dark);
  background-color: rgba(0, 197, 128, 0.1);
}

.accordion-button:focus {
  box-shadow: 0 0 0 0.25rem var(--primaryDarkTransparent);
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:hover,
.btn-primary:active {
  background-color: var(--primaryDark);
  border-color: var(--primaryDark);
}

.btn-primary:focus {
  background-color: var(--primaryDark);
  border-color: var(--primaryDark);
  box-shadow: 0 0 0 0.25rem var(--primaryDarkTransparent);
}

.form-control:focus {
  border-color: var(--primary);
  box-shadow: 0 0 0 0.25rem var(--primaryDarkTransparent);
}
